import React from "react"

const PageContent = () => {
  return (
    <div className="container">
      <div className="page-content">
        <p>
          Induction forming is an electric heat treatment and shaping process
          which can be tailored to suit specific requirements.
          <br /> Induction Bend Thermal Trace Chart
        </p>
        <p>
          For quality assurance, our induction bending machines are fully
          automated and fitted with BHP Instruments fibre optic pyrometers; and
          data acquisition for assured push button reproduction of induction
          formed bends to a range of pre-qualified bending procedures.
        </p>
        <p>
          With a total of six induction bending machines – including two Cojafex
          PB 850’s, and two semi-portables – Inductabend has the diverse
          technology and capacity to satisfy a wide range of requirements; from
          one-off ex works to full on-site dedicated machines for specialised
          pipe systems.
        </p>
      </div>
    </div>
  )
}

export default PageContent

import React from "react"

import Layout from "../components/layout"
import Hero from "../components/hero"
import PageContent from "../page-views/technology-and-quality-assurance"
import BgImage from "../assets/images/2017/08/induction-coil-slide.jpg"

const IndexPage = () => {
  const title = "Technology and Quality Assurance"
  const content = "Continuously improving and ISO 9001 accredited"

  return (
    <Layout>
      <Hero title={title} content={content} bgImage={BgImage} />
      <PageContent />
    </Layout>
  )
}

export default IndexPage
